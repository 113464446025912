@import "../../styles/variables";
.card {
  width: 100%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  .image {
    width: 100%;
    height: 100%;
    position: relative;
    max-height: 408px;
    img {
      //height: 408px;
      width: 100%;
      object-fit: cover;
    }
  }
  .favourite {
    position: absolute;
    color: $white;
    display: flex;
    align-items: flex-start;
    font-size: 12px;
    right: 0;
    top: 276px;
    z-index: 1;
    svg {
      margin-right: 0;
      width:150px;
      max-height: 100%;
      position: relative;
      top: 0;
      display: block;
    }
    .favouritePrice {
      margin-left: 4px;
      font-size: 16px;
    }
  }
  .favouriteTag {
    color: $white;
    background: $red;
    font-size: 14px;
    position: absolute;
    right: 0;
    top: 164px;
    z-index: 1;
    padding: 2px 0.5rem;
    svg {
      margin-right: 0;
      width:110px;
      position: relative;
      top: 0;
    }
    //.favouritePrice {
    //  margin-left: 4px;
    //  font-size: 16px;
    //}
  }
  .rating {
    display: block;
    svg{
      margin-right: 7px;
    }
  }
  .inner {
    padding: 10px 24px 16px;
    background: $white;
    .innerWrap {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    .detailsWrap {
      flex-grow: 1;
    }
  }
  .tagline {
    text-transform: uppercase;
    font-size: 12px;
    line-height: 16px;
  }
  .smallerHeading {
    font-size: 21px !important;
    line-height: 28px !important;
  }
  .clamp {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  h2 {
    font-family: $bodyText;
    font-size: 20px;
    color: $blue;
    margin: 4px 0 1rem;
    line-height: 26px;
    font-weight: bold;
  }
  p {
    font-size: 12px;
    line-height: 20px;
    a{
      font-weight: 600;
      color: $blue;
    }
  }
  .details {
    margin-bottom: 1rem;
    span {
      display: inline-block;
      min-width: 50px;
      color: $red;
      font-weight: 600;
      &:after{
        content:':'
      }
    }
  }
  .stars {
    display: flex;
    margin-bottom: 20px;
    svg {
      width: 20px;
      height: 20px;
      path {
        fill: $grey1;
      }
    }
  }
  .fill {
    width: 100%;
  }
  .buttonRight {
    display: flex;
    button{
      margin-right: 0;
      margin-left: auto;
    }
  }
  textarea {
    width: 100%;
    height: 140px;
    padding: 20px;
    font-family: $bodyText;
    margin-bottom: 10px;
  }
  .properties {
    display: flex;
    font-size: 10px;
    align-items: center;
    //justify-content: space-between;
    padding-bottom: 14px;
    font-weight: 500;
    img {
      //margin: 0 8px 0 14px;
      width: 32px;
    }
    svg {
      margin: 0 4px 0 8px;
    }
    img:first-child, svg:first-child {
      margin-left: 0;
    }
    &:empty {
      display: none;
    }
    &.propertiesNoSpread {
      justify-content: flex-start;
      img {
        margin: 0 8px 0 14px;
      }
    }
  }
  .tag {
    position: absolute;
    top: 18px;
    right: 24px;
    background: $white;
    border-radius: 4px;
    padding: 0 7px;
    height: 22px;
    color: $green;
    z-index: 1;
    font-weight: 200;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    .tagNumber {
      font-size: 19px;
      font-weight: 600;
      font-family: DomaineNarrow, serif;
      margin-right: 5px;
    }
  }
  .uppercaseLabel {
    text-transform: uppercase;
    font-size: 12px;
    margin-bottom: 10px;
    display: block;
  }
  .footer {
    border-top: 1px solid #cbcbcb;
    display: flex;
    align-items: flex-end;
    padding-top: 5px;
    .label {
      display: block;
      font-size: 13px;
      color: $green;
      font-family: DomaineNarrow, serif;
    }
    .price {
      font-size: 20px;
      font-weight: 800;
    }
    .total {
      font-size: 12px;
      text-decoration: underline;
      margin-left: 15px;
      position: relative;
      top: 1px;
      .totalValue {
        font-size: 14px;
        font-weight: 600;
        position: relative;
        top: -1px;
      }
    }
    .actions {
      margin-left: auto;
      display: flex;
      align-items: center;
      position: relative;
      top: 2px;
      button, span:last-of-type, a {
        margin: 0 0 0 10px;
        width: 85px !important;
        line-height: normal;
      }
      .favouriteSpan {
        width: 18px !important;
        cursor: pointer;
      }
    }
  }
  &.small {
    width: 100%;
    display: flex;
    @media (min-width: $phone) {
      width: 588px;
    }
    .favourite {
      top: 0;
    }
    .image {
      height: 132px;
      width: 202px;
      display: none;
      @media (min-width: $phone) {
        display: block;
      }
    }
    .inner {
      padding: 0;
      @media (min-width: $phone) {
        padding: 0 24px;
      }
    }
    h2 {
      font-size: 18px;
      line-height: 24px;
      margin: 0 0 1rem;
      @media (min-width: $phone) {
        padding-right: 100px;
      }
    }
    .label {
      display: inline-block;
      margin-right: 6px;
    }
  }
  &.landscape {
    display: block;
    .favourite {
      top: 368px;
    }
    @media (min-width: $tabletLand) {
      display: table;
      .favourite {
        top: 0;
      }
      > div {
        display: table-cell;
      }
    }

  }
  &.vertical {
    height: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba($blue, 0);
    > .inner {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      height: auto !important;
      .innerMiddle {
        flex-grow: 1;
      }
    }
    .image, .image img {
      height: 260px;
      transition: transform 0.6s;
      overflow: hidden;
    }
    .image img {
      position: absolute;
    }
    .favourite {
      top: 240px;
    }
    &:hover {
      border: 1px solid rgba($blue, 0.1);
      .image {
        img {
          transform: scale(1.1);
        }
      }
    }
  }
  &.offer {
    width: 290px;
    display: flex;
    flex-direction: column;
    background: $white;
    margin: 0 2rem 55px 2rem;
    .image, .image img {
      height: 192px;
    }
    .favourite {
      top: 175px;
    }
    .inner {
      padding-top: 24px;
      background: $white;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      p{
        //height: 80px;
        overflow: hidden;
      }
    }
    .footer {
      align-items: center;
      padding-top: 10px;
      margin-top: auto;
      .actions {
        margin: 0 auto;
      }
    }
  }
  &.gallery {
    max-width:1185px;
    .header {
      display: flex;
      align-items: center;
      .tagline {
        margin-right: 18px;
        @media (min-width: $phone) {
          max-width: 120px;
        }
      }
      .icon {
        width: 42px;
        margin-right: 18px;
        > svg {
          max-height: 100%;
        }
      }
    }
    .details {
      margin-bottom: 10px;
      @media (min-width: $desktop) {
        display: flex;
      }
      li {
        margin-right: 6%;
        &:last-child {
          margin: 0;
        }
      }
    }
    .hotelText {
      display: block;
      span {
        cursor: pointer;
        font-weight: 700;
        color: $blue;
      }
    }
    .gallery {
      position: relative;
      max-height: 444px;
      @media (min-width: $tabletLand) {
        //display: table-cell;
        vertical-align: top;
        width: 50%;
      }
      height: 100%;
      .image {
        height: 100%;
        > span {
          max-height:100%;
        }
      }
      > div > div:nth-child(2) {
        position: relative;
        //&:after {
        //  content: '';
        //  width: 100%;
        //  height: 100%;
        //  position: absolute;
        //  top: 0;
        //  left: 0;
        //  //box-shadow: inset 0px -40px 100px 0px rgba(0,0,0,1);
        //}
      }
    }
    .chevronRight,
    .chevronLeft {
      width: 50px;
      background: none;
      border: 0;
      position: relative;
      padding:0 15px;
      outline: none;
      transition: transform 0.2s ease-out;
      cursor: pointer;
      svg path {
        fill: $white;
      }
      &:hover {
        transform: scale(1.1);
      }
      &.disabled {
        opacity: 0;
      }
    }
    .chevronLeft {
      transform: rotateZ(180deg);
      &:hover {
        transform: rotateZ(180deg) scale(1.1);
      }
    }
    li button {
      svg {
        fill: $white;
      }
    }
    .inner {
      @media (min-width: $phone) {
        padding: 24px 5% 24px;
        height: 100%;
        //display: table-cell;
      }
    }
    h2{
      @media (min-width: $phone) {
        font-size: 24px;
        line-height: 30px;
        margin: 0.5rem 0 1rem;
        font-weight: bold;
      }
    }
    p{
      @media (min-width: $phone) {
        margin: 0 0 1.5rem;
        font-size: 14px;
        line-height: 22px;
      }
    }
    .properties {
      @media (min-width: $phone) {
        padding-bottom: 24px;
      }
      > span {
        display: none;
        margin-right: 0.5rem !important;
        @media (min-width: $phone) {
          display: block;
        }
        &:last-of-type {
          margin-right: 0 !important;
        }
      }
    }
    .actions button, .actions span:last-of-type {
      @media (min-width: $phone) {
        width: 135px !important;
      }
    }
    .footer {
      @media (min-width: $phone) {
        padding-top: 10px;
      }
    }
    .slider {
      height: 100% !important;
    }
  }
  &.booking {
    background: $white;
    display: flex;
    flex-direction: row;
    @media (max-width: $tablet) {
      display: block;
    }
    .gallery {
      //width: 262px;
      //height: 214px;
      @media (max-width: $tablet) {
        width: 100%;
        height: auto;
        display: block;
      }
      ul, li {
        height: 100% !important;
      }
      > div:first-child {
        @media (max-width: $tablet) {
          //height: 300px !important;
        }
      }
      & .image {
        max-height: 267px;
      }
    }
    .inner {
      padding: 24px 20px;
      & > div:first-child {
        display: flex;
        align-items: center;
        @media (max-width: $tablet) {
          display: block;
        }
        h2 {
          font-size: 18px;
          line-height: 24px;
        }
      }
    }
    .spacer {
      margin-left: auto;
      padding-left: 35px;
      @media (max-width: $tablet) {
        display: none;
      }
      > svg {
        width: 11px;
      }
    }
    .overview {
      margin: 0;
      span {
        color: $green;
        font-weight: 500;
      }
    }
    .bookingDetails {
      width: 183px;
      padding-left: 35px;
      flex-shrink: 0;
      font-size: 12px;
      line-height: 18px;
      @media (max-width: $tablet) {
        padding: 20px 0 0;
      }
      .label {
        display: flex;
        align-items: center;
        border: 1px solid $grey2;
        color: $grey2;
        font-weight: 600;
        margin-bottom: 12px;
        padding: 3px;
        line-height: 14px;
      }
      svg {
        margin: 0 3px 0 2px;
      }
      .labelPending {
        color: $red;
        text-decoration: underline;
        &:before {
          content: '';
          width: 15px;
          height: 15px;
          background: $red;
          border-radius: 50%;
          display: inline-block;
          margin: 0 3px 0 2px;
        }
      }
      .reference {
        display: block;
        font-weight: 600;
      }
      button {
        margin-top: 24px;
      }
    }
    .bookingDetailsInline {
      padding: 20px 40px 0 25px;
      width: 100%;
      border-right: 1px solid $grey1;
      @media (max-width: $tablet) {
        border: none;
      }
      .label {
        background: rgba($green,0.05);
        font-weight: 400;
        border: none;
        padding: 6px;
        svg {
          border-radius: 50%;
          height: 15px;
          width: 15px;
          overflow: hidden;
          background: rgba($green,0.5);
          padding: 3px;
          color: white;
          margin-right: 3px;
        }
        b {
          color: $green;
        }
      }
      h3 {
        font-family: $bodyText;
        font-size: 18px;
        margin: 20px 0 10px;
      }
      a {
        font-size: 14px;
        color: $blue;
        text-decoration: underline;
      }
    }
    .check {
      display: flex;
      margin: 17px 0;
      width: 406px;
      @media (max-width: $phone) {
        width: 100%;
      }
      .label {
        display: flex;
        font-size: 12px;
        font-weight: 500;
        color: $grey2;
      }
      .labelPending {
        display: flex;
        align-items: center;
        color: $red;
        margin-left: 4px;
        text-decoration: underline;
        &:after {
          content: '';
          width: 15px;
          height: 15px;
          background: $red;
          border-radius: 50%;
          display: inline-block;
          margin: 0 3px 0 2px;
        }
      }
      .time {
        display: block;
        margin-top: 14px;
        font-size:24px;
      }
      & > div {
        flex: 1;
        &:last-child:not(:only-child) {
          position: relative;
          margin-left: 18px;
          padding-left: 18px;
          &:after {
            content: '';
            height: 74px;
            border-left: 1px solid $grey1;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
  &.smallGallery {
    .details {
      @media (min-width: $desktop) {
        display: block;
      }
    }
    .properties {
      span {
        display: none;
      }
    }
  }
}
