@import "variables";

p a:not(.button) {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-decoration-color: rgba($blue, 0.4);
}
.boldFirst > p:first-child {
  font-weight: bold;
  font-size: 1rem;
  margin-left: 0;
}
.tag {
  color: $green;
  font-family: $bodyText;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.1rem;
  text-transform: uppercase;
  display: block;
  letter-spacing: 2px;
}
.sectionHeading {
  font-size: 2rem;
  line-height: 2.5rem;
  color: $blue;
  position: relative;
  @media (min-width: 1440px) {
    font-size: 2.25rem;
    line-height: 3rem;
  }
}
.sectionHeading2 {
  @extend .sectionHeading;
  @media (min-width: 1440px) {
    font-size: 2.5rem;
    line-height: 3rem;
  }
}
.pageHeading {
  font-family: DomaineNarrow, serif;
  font-weight: 600;
  font-size: 48px;
  line-height: 60px;
  color: $blue;
  position: relative;
}
.centered {
  text-align: center;
}
.regular {
  font-family: Domaine, serif;
  font-weight: 500;
}
.blogSearch {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 2rem;
}
.blogSearchForm {
  & button {
    position: relative;
    border: none;
    background-color: transparent;
    outline: none;
    height: 19px;
    color: $blue;
    & svg {
      width: 19px;
      height: 19px;
    }
  }
  & input {
    font-family: DomaineNarrow;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    border: none;
    border-bottom: 1px solid #CCCCCC;
    background-color: transparent;
    outline: none;
    width: 130px;
    padding: 5px;
    &::placeholder {
      color: #A2A2A2;
    }
  }
}
.dropCap {
  &:first-letter {
    float: left;
    font-size: 48px;
    font-family: Domaine, serif;
    margin: 0.1em 0.1em 0.2em 0;
    line-height: 0.65px;
  }
}
.categoryTags {
  & span {
    display: inline-block;
    color: $green;
    font-size: 8px;
    font-weight: 600;
    background-color: rgba(123,202,210,0.3);
    border-radius: 12px;
    padding: .5rem;
    line-height: 1px;
  }
}
.withBorder {
  padding-bottom: 16px;
  margin-bottom: 16px;
  position: relative;
  &:after {
    content: "";
    display: block;
    width: 83px;
    background-color: $red;
    height: 2px;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translatex(-50%);
  }
}
.withBottomMargin {
  margin-bottom: 16px;
}
.smallStamp {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 68px;
  top: -20px;
  & svg {
    width: 100%;
  }
}
.midStamp {
  width: 60px;
  height: 60px;
  position: absolute;
  left: 68px;
  top: -30px;
  & svg {
    width: 100%;
  }
}
.popupList {
  text-align: center;
  padding: 16px 0;
  display: block;
  color: #00243F;
  font-family: Domaine, serif;
  cursor: pointer;
  border-top: 1px solid #979797;
  font-size: 1.125rem;
}
.indexed {
  position: relative;
  z-index: 1;
}
.ElementsApp, .ElementsApp .InputElement {
  color: #333333;
  font-family: $bodyText;
}
.clamped {

}
.backToHotel {
  position: absolute;
  top: 2rem;
  right: 2rem;
  background: $red;
  color: $white;
  z-index: 2;
  padding: 0.5rem 1rem;
  border-radius: 50px;
  font-weight: 600;
}

.accordion__button[aria-expanded="true"] svg {
  transform: rotate(180deg);
}
